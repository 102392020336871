import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Container from '@/src/common/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import ProductCard from '@/components/ProductCard'
import { Mixpanel } from '@/utils/mixpanel';
import { canProducerShipToSelectedState, getSelectedStateName } from '@/utils/statesHelper';
import Meta  from '@/src/components/Meta';
import ProducerGallery from './ProducerGallery';
import ProducerAbout from './ProducerAbout';
import Loading from '@/components/Loading';
import NotFound from '@/views/supportingPages/NotFound';
import { useRouter } from "next/router";
import Link from 'next/link'
import NarrowContainer from '@/src/common/NarrowContainer';
const GENERIC_HERO_IMAGE =
  'https://images.pexels.com/photos/357742/pexels-photo-357742.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940';

import { useSite } from '@/src/contexts/SiteContext';
import { ImageHero } from '../../Hero';
import { Faq } from '../..';
import BrandProducts from './BrandProducts'
import UniqueSellingPoints from '../../UniqueSellingPoints';

export const brandContainMedia = (brand) => {
  if (brand?.video) {
    return true;
  }
  if (brand?.media && brand.media.length > 0) {
    return true;
  }
  return false;
};

const ProducerPage = ({pageData}) => {
  const theme = useTheme();
  const router = useRouter();
  const { site } = useSite()
  
  const [producer, setProducer] = useState(pageData?.producer);
  const [products, setProducts] = useState(pageData?.products);
  const [dataSaveStatus, setDataSaveStatus] = useState();
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) ;
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const template = producer?.template;
  const slug = router.query.slug;
  useEffect(() => {
    if (!router.isReady) return

    Mixpanel.track('Producer page', {
      slug,
      siteKey: site?.key,
    })

    setProducer(pageData?.producer)
    setProducts(pageData?.products)
  }, [
    isMd,
    router.asPath,
    router.isReady,
    pageData?.producer,
    pageData?.products,
  ])


  // if (!producerId || !producerData) {
  //   console.error('Couldn\'t find data for producer ' + producerId);
  //   history.push('/page-not-found');
  //   return;
  // }
  
  const infoCompLocation = true ? 'Top' : 'Bottom';
  let producerInfoComp;
  if (producer?.template?.internal_name === "mlb-wines") {
    producerInfoComp = (
      <ProducerAbout producer={producer} />
    )
  } else if (producer && producer.about) { 
    producerInfoComp = (
      <Container>
        <Box
          marginTop={3}
          display="flex"
          flexDirection={isMd ? 'row' : 'column'}
          gap={2}
        >
          <Box width={isMd && brandContainMedia(producer) ? '50%' : '100%'}>
            <ProducerAbout producer={producer} />
          </Box>
          {brandContainMedia(producer) && (
            <Box width={isMd ? '50%' : '100%'} display="flex" justifyContent="center">
              <ProducerGallery producer={producer} />
            </Box>
          )}
        </Box>
      </Container>
    )
  }

  const validState = canProducerShipToSelectedState(producer);

  if (!producer) {
    return (<NotFound/>);
  }
  const seo = producer.seo || {};

  //const infoCompLocation = producer.template.information_location
  let heroCard;
  if (template?.internal_name === 'mlb-wines'){
    heroCard = {
      title: 'Wine from your Team',
      subtitle: 'Support your MLB team with limited edition wines',
      cta: 'Shop now',
      onClick: () => executeScroll(),
    }
  }
  console.log({template});
  const heroImage = (
    <ImageHero
      heroImg={{
        src:
          (!isMd && producer?.hero_mobile?.src
            ? producer?.hero_mobile?.src
            : producer?.hero) || GENERIC_HERO_IMAGE,
      }}
      card={heroCard}
    />
  )
  const heroClickUrl = template?.hero_properties?.click_relative_url
  return (
    <Box>
      <Meta
        site={site}
        title={`${seo.title || producer?.name}`}
        description={seo.description || producer?.title}
        previewImage={seo.preview_image?.src || producer?.hero}
        keywords={seo.keywords}
        url={`https://sipjoy.shop/brand/${slug}`}
      />

      {dataSaveStatus && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          autoHideDuration={6000}
          onClose={() => setDataSaveStatus(null)}
        >
          <Alert severity={dataSaveStatus?.severity} sx={{ width: '100%' }}>
            {dataSaveStatus?.message}
          </Alert>
        </Snackbar>
      )}

      {heroClickUrl ? (
        <Link href={heroClickUrl}>
          <a>{heroImage}</a>
        </Link>
      ) : (
        heroImage
      )}

      {validState === false && (
        <Alert severity="error">
          <Typography sx={{ fontWeight: '700' }}>
            This winery do NOT ship to {getSelectedStateName()}{' '}
          </Typography>
        </Alert>
      )}
      <Box>
        <NarrowContainer sx={{ paddingTop: 4, paddingBottom: 4 }}>
          {producer.title && (
            <Box display="flex" justifyContent="center" marginBottom={3}>
              <Typography
                variant="h4"
                color={theme.palette.primary.main}
                sx={{
                  fontWeight: 700,
                }}
              >
                {producer.title}
              </Typography>
            </Box>
          )}
          {infoCompLocation === 'Top' && producerInfoComp}
          <div style={{ position: 'relative' }}>
            <div
              ref={myRef}
              style={{ position: 'absolute', top: '-50px', left: 0 }}
            ></div>
          </div>
          <BrandProducts products={products} brand={producer} />
        </NarrowContainer>
      </Box>
      {infoCompLocation === 'Bottom' && producerInfoComp}

      {template?.faq_section_in_brand_page ? (
        <Box
          bgcolor={theme.palette.background.level1}
          sx={{ marginTop: '80px', padding: '40px' }}
        >
          <Container sx={{ maxWidth: '800' }}>
            <Faq faqSection={template?.faq_section_in_brand_page} />
          </Container>
        </Box>
      ) : null}

      {template?.internal_name === 'mlb-wines' ? (
        <Box
          sx={{
            marginTop: '80px',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
        >
          <Container>
            <Box marginBottom={5}>
              <Typography color={'secondary'} align="center">
                MLB Wine Brands
              </Typography>
              <Typography align="center" variant="h4">
                The Classiest way to show support
                <br />
                for your team!
              </Typography>
            </Box>
            <UniqueSellingPoints
              points={[
                {
                  title: 'Etched Wines',
                  subtitle:
                    "This isn't some plonk wine, each is specifically made for our MBL project with etched logos!",
                },
                {
                  title: 'Collector Item',
                  subtitle:
                    'These are limited edition and when they are gone they are GONE',
                },
                {
                  title: 'Amazing Gift',
                  subtitle:
                    'Have a passionate MLB fan in your live who also loves wine? Look no further!',
                },
                {
                  title: 'The Best Deal',
                  subtitle:
                    'You may have seen these elsewhere, but buying direct from the source gives you the BEST deal',
                },
              ]}
            />
          </Container>
        </Box>
      ) : null}
    </Box>
  )
};
export default ProducerPage;
