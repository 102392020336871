import { Box } from "@mui/system"
import ProductCard, { ProductCardRefresh } from "../../ProductCard"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSite } from '@/src/contexts/SiteContext'
import { Loading } from "../..";
const PRODUCT_CARD_REF = 'producer_page';


export default function BrandProducts({brand, products}) {
  const theme = useTheme();
  const { site } = useSite()
  const brandTemplate = site?.brand_template
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  if (!products) {
    return (
      <Box display="flex" justifyContent="center" sx={{ width: '100%' }}>
        <Loading color="white" />
      </Box>
    )
  }
  if (brandTemplate?.product_card === 'Refresh') {
    return (
      <Box display="flex" flexDirection={'column'} gap={8}>
        {products.map((product, i) => {
          let card;
          if (!isMd) {
            card ='Image above text'
          } else {
            card = i % 2 ? 'Image on the left' : 'Image on the right'
          }
          return (
            <Box key={i} data-aos={'fade-up'} flexGrow={2}>
              <ProductCardRefresh
                product={product}
                locRef={PRODUCT_CARD_REF}
                card={card}
              />
            </Box>
          )
        })}
      </Box>
    )
  }
  return (
    <Box
      display="flex"
      flexDirection={isMd ? 'row' : 'column'}
      gap={2}
      flexFlow="wrap"
    >
      {products.map((item, i) => {
        return (
          <Box key={i} data-aos={'fade-up'} flexGrow={2}>
            <ProductCard
              product={{ ...item, brand }}
              locRef={PRODUCT_CARD_REF}
              showSourceInsteadDetails={false}
            />
          </Box>
        )
      })}
    </Box>
  )
  
}