import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Link,
  Button,
  Dialog,
  DialogContent,
  IconButton,
} from '@mui/material'
import ReactPlayer from 'react-player'
import { useTheme, alpha } from '@mui/material/styles'
// import Carousel, { Modal, ModalGateway } from 'react-images';
import Image from 'next/image'
import CloseIcon from '@mui/icons-material/Close'
import { Carousel } from 'react-responsive-carousel'
import useMediaQuery from '@mui/material/useMediaQuery';

const ProducerGallery = ({ producer }) => {
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const hasGallery = producer?.media && producer?.media.length > 0
  const images =
    hasGallery &&
    producer.media.map((url, i) => {
      const u = url.replace('undefined', '')
      return (
        <div key={i}>
          <img src={u} key={u} />
        </div>
      )
    })
  let galleryComp
  if (producer?.video) {
    galleryComp = (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        sx={{ minHeight: '250px' }}
      >
        <ReactPlayer
          height={isMd ? '255px' : '172px'}
          width={isMd ? '450px' : '300px'}
          playing={true}
          loop={true}
          controls={true}
          muted={true}
          url={producer.video}
        />
        {hasGallery > 0 && (
          <Box sx={{ float: 'right' }}>
            <Link onClick={() => setOpen(true)}>{'More images...'}</Link>
          </Box>
        )}
      </Box>
    )
  } else if (hasGallery > 0) {
    const img = producer.media[0]
    galleryComp = img && (
      <Box
        position={'relative'}
        borderRadius={4}
        boxShadow={3}
        bgcolor={alpha(theme.palette.background.paper, 0.4)}
        padding={3}
        onClick={() => setOpen(true)}
        sx={{
          cursor: 'pointer',
          height: '300px',
          width: '300px',
          position: 'relative',
        }}
      >
        <Image layout="fill" src={img} objectFit={'contain'} />
        <Box
          position={'absolute'}
          right={1}
          bottom={1}
          sx={{ backgroundColor: 'white' }}
        >
          <Link onClick={() => setOpen(true)}>{'More images...'}</Link>
        </Box>
      </Box>
    )
  }
  return (
    <>
      {/* <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photosCarousel.map((x) => ({
                ...x,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway> */}
      {galleryComp}
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogContent>
          <IconButton
            sx={{ position: 'absolute', right: '0', top: '0' }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
          <Carousel>{images}</Carousel>
        </DialogContent>
      </Dialog>
    </>
  )
}

ProducerGallery.propTypes = {
  producer: PropTypes.object.isRequired,
}
export default ProducerGallery
