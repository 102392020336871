import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import { useSite } from '@/src/contexts/SiteContext';
import MarkdownView from '@/src/components/Markdown/MarkdownView';

const ProducerAbout = ({producer}) => {
  const { site } = useSite()
  const brandTemplate = site?.brand_template
  const [expanded, setExpanded] = useState(false);

  const about = producer?.about
  if (!about) {
    return null;
  }


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  const shortAbout = about.substr(0, 800);
  let contentSource, showExpandButton;
  if (shortAbout === about) {
    contentSource = about;
    showExpandButton = false;
  } else if (expanded) {
    contentSource = about;
    showExpandButton = true;
  } else {
    contentSource = `${shortAbout}...`;
    showExpandButton = true;
  }

  let attr;
  if (producer.template?.internal_name === "mlb-wines") {
    attr={ textAlign: 'center', paddingBottom: 4 }
  }
  return (
    <Box sx={attr}>
      <MarkdownView >{contentSource}</MarkdownView>
      {showExpandButton && (
        <Box display="flex" flexDirection={'row'} justifyContent={'center'}>
          <Button onClick={handleExpandClick} variant="text">
            {expanded ? 'READ LESS' : 'READ MORE'}
          </Button>
        </Box>
      )}
    </Box>
  );
  
};

ProducerAbout.propTypes = {
  producer: PropTypes.object.isRequired,
};
export default ProducerAbout;
